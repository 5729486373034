import { Controller } from "@hotwired/stimulus";
import type { Editor } from "@tiptap/core";
import type { EditorView } from "prosemirror-view";
import type { Slice } from "prosemirror-model";
import FileEmbedUploadStartEvent from "../events/file_embed_upload_start";
import type EmbedFile from "../embed/embed_file";

interface TurboMorphAttributeEvent extends Event {
  detail: {
    attributeName: string;
    mutationType: "updated" | "removed";
  };
}

function runToggleEvent(editor: Editor, node: HTMLElement) {
  const event = node.dataset.editorToggle;

  if (event === undefined) {
    console.log(`Node did not have editorToggle member of dataset`, node);
    return;
  }

  const toggleEvent = `toggle${event}`;

  const chain = editor.chain().focus();

  if (!(toggleEvent in chain)) {
    console.error(`Unknown event: ${toggleEvent}`);
    return chain.run();
  }

  const elem = (chain as any)[toggleEvent] as any;

  if (typeof elem !== "function") {
    console.log(`Property ${toggleEvent} is not a function on chain`, elem);
    return;
  }

  elem().run();
}

export default class RichTextEditorController extends Controller {
  static targets = ["input", "textarea", "editorButton", "embedContainer"];
  static values = {
    initial: String,
    embedSgid: String,
    embedTarget: String,
  };

  readonly inputTarget!: HTMLElement;
  readonly textareaTarget!: HTMLTextAreaElement;
  readonly editorButtonTargets!: HTMLElement[];
  readonly embedContainerTarget!: HTMLElement;
  readonly initialValue!: string;
  readonly embedSgidValue!: string;
  readonly embedTargetValue!: string;

  editor: Editor | undefined;

  textareaTargetConnected(target: Element) {
    if (target instanceof HTMLElement) {
      target.hidden = true;
    }
  }

  inputTargetDisconnected() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  initialValueChanged(value: string) {
    const { editor } = this;

    if (editor === undefined) return;

    editor.commands.setContent(value, true);
  }

  connect() {
    this.reconnect();
  }

  reconnect(): Promise<void> {
    if (this.editor) {
      this.editor.destroy();
    }

    return this.attachEditor();
  }

  // Attach the editor.
  async attachEditor() {
    const [
      { Editor },
      { default: StarterKit },
      { default: Link },
      { CommhexEmbeddedFile },
      { default: CommhexEmbeddingFile },
    ] = await Promise.all([
      import("@tiptap/core"),
      import("@tiptap/starter-kit"),
      import("@tiptap/extension-link"),
      import("../tiptap/commhex_embedded_file"),
      import("../tiptap/commhex_embedding_file"),
    ]);

    this.editor = new Editor({
      element: this.inputTarget,
      extensions: [StarterKit, Link, CommhexEmbeddedFile, CommhexEmbeddingFile],
      content: this.initialValue,
      onBlur: () => this.blurEditor(),
      onTransaction: () => this.syncEditorState(),
      editorProps: {
        handleDrop: this.handleDrop,
        attributes: {
          id: this.editorID,
        },
      },
    });
    this.hideFallback();
  }
  handleDrop = (
    view: EditorView,
    event: DragEvent,
    _slice: Slice,
    moved: boolean
  ) => {
    if (
      moved ||
      !(
        event.dataTransfer &&
        event.dataTransfer.files &&
        event.dataTransfer.files.length > 0
      )
    ) {
      return false;
    }
    const coords = view.posAtCoords({
      left: event.clientX,
      top: event.clientY,
    });

    const pos = coords === null ? 0 : coords.pos;

    Array.from(event.dataTransfer.files).forEach((file) => {
      const description = window.prompt(
        `Add description for file ${file.name}`
      );
      if (description === null) return;
      this.uploadFile(file, description, pos);
    });

    return true;
  };

  async embedUploadStarted(event: FileEmbedUploadStartEvent) {
    const { embed, editorPos } = event.detail;
    return await this.addEmbeddedFile(embed, editorPos);
  }

  // Add an embedded file at the given position. Will set a placeholder while the upload happens, then
  // add the actual file.
  async addEmbeddedFile(embed: EmbedFile, editorPos: number | undefined) {
    let { editor } = this;
    if (editor === undefined) {
      return;
    }

    const [
      { findUploadingPlaceholder, uploadingPlugin },
      { FileUploadFailedError },
    ] = await Promise.all([
      import("../tiptap/commhex_embedding_file"),
      import("../embed/embed_file"),
    ]);

    let tr = editor.view.state.tr;

    // Add in a placeholder for the uploading file.
    tr.setMeta(uploadingPlugin, {
      add: { embed, pos: editorPos ?? tr.selection.from },
    });
    editor.view.dispatch(tr);

    try {
      const randomKey = await embed.finished;
      const pos = findUploadingPlaceholder(editor.state, embed);

      if (pos) {
        const element = editor.schema.nodes["commhex-embedded-file"].create({
          "random-key": randomKey,
        });
        const transact = editor.view.state.tr
          .replaceWith(pos, pos, element)
          .setMeta(uploadingPlugin, { remove: { embed } });
        editor.view.dispatch(transact);
      }
    } catch (err: unknown) {
      if (!(err instanceof FileUploadFailedError)) {
        // If we failed to upload, blow up the problem.
        editor.view.dispatch(
          editor.view.state.tr.setMeta(uploadingPlugin, { remove: { embed } })
        );
      }
      throw err;
    } finally {
      this.blurEditor();
    }
  }

  async uploadFile(file: File, description: string, pos: number) {
    const { embedSgidValue, embedTargetValue, editor } = this;
    if (
      embedSgidValue === "" ||
      embedTargetValue === "" ||
      editor === undefined
    ) {
      return;
    }
    const { default: EmbedFile } = await import("../embed/embed_file");

    const embed = new EmbedFile(
      file,
      description,
      embedSgidValue,
      embedTargetValue
    );
    this.addEmbeddedFile(embed, pos);
  }

  get editorID(): string {
    if (this.element.id) {
      return `${this.element.id}__editor_inner`;
    } else return ``;
  }

  hideFallback() {
    this.textareaTarget.hidden = true;
  }

  toggleState(click: InputEvent) {
    if (this.editor === undefined) return;

    const { target } = click;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    runToggleEvent(this.editor, target);
  }

  syncEditorState() {
    const { editor } = this;
    if (!editor) return;

    this.editorButtonTargets.forEach((target) => {
      const toggle = target.dataset.editorActivation;

      if (toggle === undefined) return;
      const isActive = editor.isActive(toggle);
      if (isActive) {
        target.classList.add("active");
      } else {
        target.classList.remove("active");
      }
    });
  }

  blurEditor() {
    const { editor } = this;

    if (editor === undefined) return;

    this.textareaTarget.value = editor.getHTML();
  }

  editorHTML(): string {
    if (this.editor === undefined) {
      return "";
    }

    return this.editor.getHTML();
  }

  disconnect() {
    this.editor = undefined;
  }

  preventHiddenMorph(event: TurboMorphAttributeEvent) {
    if (event.detail.attributeName === "hidden") {
      event.preventDefault();
    }
  }

  preventIfActive(event: Event) {
    if (this.editor === undefined) {
      return;
    }

    if (this.element.contains(document.activeElement)) {
      event.preventDefault();
    }
  }

  preventMorph(event: Event) {
    event.preventDefault();
  }
}
